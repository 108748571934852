@use '@angular/material' as mat;
@import 'node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
@import '@pushdr/stylekit/scss/stylekit.portal.scss';

@import '@angular/material/theming';
@include mat.core();
$candy-app-primary: mat.define-palette(mat.$indigo-palette, A700);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$candy-app-warn: mat.define-palette(mat.$red-palette);
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include mat.all-component-themes($candy-app-theme);

* {
  font-size: 14px;
  color: #263237;
}

.title {
  font-size: 24px;
}

/* ng-select */
@import '@ng-select/ng-select/themes/default.theme.css';

.ng-input {
  input {
    padding: 0;
    margin: auto;
  }
}

.ng-select {
  &.ng-select-single {
    .ng-select-container {
      height: 44px;
      border-radius: 4px;
      border: 2px solid $borderColor-bluegrey-200;
    }
  }

  .ng-select-container {
    .ng-value-container {
      padding-left: 16px;
    }
  }
}

.ng-dropdown-panel {
  border: 2px solid $borderColor-bluegrey-200;
  .ng-dropdown-panel-items {
    .ng-option {
      padding: 15px 16px;
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: auto;
}

.ng-select.ng-select--no-margin .ng-select-container {
  margin-top: 0;
}

.c-form-part__entry--is-invalid .ng-select-container {
  border: 1px solid $orange-sunset;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 28%;
  left: 12px;
}

/* Partner Portal */

.pdr-portal-table {
  th {
    @apply border-b-2 border-grey-300;
  }
  td,
  th {
    @apply p-3 text-base;
    a {
      @apply text-indigo-500;
    }
  }
  thead th {
    @apply text-grey-700 font-medium text-left;
  }
  tbody {
    td.spacer {
      @apply h-20;
    }
    td.loading {
      @apply text-center text-lg p-10;
    }
    tr {
      @apply border-t border-grey-300;
      &:hover > td {
        @apply bg-indigo-100;
      }
    }
  }
  &.loading {
    @apply relative;
    &:after {
      @apply inset-0 absolute text-center py-20 bg-grey-100 bg-opacity-75 text-bluegrey-800 font-medium text-lg;
      content: 'Loading...';
    }
  }
}

.pagination {
  @apply p-6 text-base text-center border-t-2 border-grey-300 text-grey-700 mt-10 mx-8;
  button {
    @apply mx-4 font-medium text-grey-500 px-2 py-1 text-lg;
    &:hover {
      @apply bg-grey-200;
    }
  }
}

.filters-bar {
  @mixin field-style {
    @apply bg-transparent border rounded border-bluegrey-400 ml-10 h-10;
  }
  $fields: 'input, select';
  .input-wrap {
    @include field-style;
    @apply inline-block ml-10 pl-2 flex align-middle items-center;
    #{$fields} {
      @apply ml-0 border-0 outline-none flex-1;
    }
  }
  #{$fields} {
    @include field-style;
    @apply p-2 text-bluegrey-800;
  }
  label {
    @apply text-grey-500 ml-10;
    #{$fields} {
      @apply ml-2;
    }
    &:first-child {
      @apply ml-0;
    }
  }
  @apply mb-10 flex;
}

dl.info-panel {
  @apply bg-transparent border rounded border-bluegrey-400 p-4 flex-col h-20 flex flex-wrap text-base;
  dt {
    @apply text-grey-700 font-medium text-base mb-2;
  }
  dd {
    @apply text-indigo-700 font-medium text-base;
  }
  dt,
  dd {
    @apply m-0;
  }
}

.pd-drop-down {
  @apply relative inline-block;
  section.label {
    @apply h-5;
  }
  section.options {
    display: none;
  }
  &:hover section.options {
    @apply block border rounded border-bluegrey-400 mt-5 absolute w-full cursor-pointer bg-white;
    min-width: 15em;
    z-index: 2;
    span,
    header {
      @apply block text-bluegrey-800 font-medium p-2;
    }
    span {
      &:hover {
        @apply bg-indigo-100;
      }
    }
    header {
      @apply text-grey-300 border-b border-grey-300;
    }
  }
}
